var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "expandHeader" },
    [
      _c(
        "v-container",
        [
          _c("the-layout-header", {
            attrs: {
              title: _vm.$t("home.access-my-wallet.title"),
              "subtitle-line-one": _vm.$t("home.access-my-wallet.subtitle-one"),
              "subtitle-line-two": _vm.$t("home.access-my-wallet.subtitle-two"),
              "route-obj": _vm.titleRoute,
              "has-link": "",
            },
          }),
          _c(
            "div",
            { staticClass: "mx-auto", staticStyle: { "max-width": "650px" } },
            _vm._l(_vm.buttons, function (btn, key) {
              return _c(
                "div",
                { key: key, staticClass: "position--relative" },
                [
                  btn.official
                    ? _c(
                        "div",
                        {
                          staticClass: "chip-official d-flex align-center",
                          class: _vm.isMobile
                            ? "note-position-mobile"
                            : "note-position",
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-1",
                              attrs: { color: "whiteAlways", size: "15px" },
                            },
                            [_vm._v(" mdi-shield-check ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "font-weight-medium letter-spacing--initial line-height--initial",
                            },
                            [_vm._v(" Official ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !btn.recommended
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "orangePrimary--text mew-label note-position d-flex align-center",
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-1",
                              attrs: { color: "orangePrimary", size: "18px" },
                            },
                            [_vm._v(" mdi-shield-alert ")]
                          ),
                          _vm._v(" NOT RECOMMENDED "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "mew-button",
                    {
                      class: [
                        btn.title === "Software"
                          ? "AccessWalletSoftwareButton"
                          : "mb-5 py-6",
                      ],
                      staticStyle: { height: "initial", "min-height": "157px" },
                      attrs: {
                        "has-full-width": "",
                        "color-theme": btn.color,
                        "btn-style": btn.style === "outline" ? "outline" : "",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return btn.fn.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "width--full d-flex align-center text-left",
                        },
                        [
                          btn.icon && !_vm.isMobile
                            ? _c("img", {
                                staticClass: "ml-5 mr-6",
                                staticStyle: { height: "70px" },
                                attrs: { src: btn.icon, alt: btn.alt },
                              })
                            : _vm._e(),
                          _c("div", { staticClass: "px-3" }, [
                            _c("div", { staticClass: "d-flex align-center" }, [
                              btn.icon && _vm.isMobile
                                ? _c("img", {
                                    staticClass: "mr-4",
                                    staticStyle: { height: "40px" },
                                    attrs: { src: btn.icon, alt: btn.alt },
                                  })
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mew-heading-2 break-word letter-spacing--initial",
                                },
                                [_vm._v(" " + _vm._s(btn.title) + " ")]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mew-heading-4 reset-subtitle break-word letter-spacing--initial text-transform--none mt-4",
                              },
                              [_vm._v(" " + _vm._s(btn.subtitle) + " ")]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
          _c("div", { staticClass: "spacer-y-medium" }),
          _c("module-access-wallet-mobile", {
            attrs: { open: _vm.showMobile, close: _vm.close },
          }),
          _c("module-access-wallet-hardware", {
            attrs: { open: _vm.showHardware, close: _vm.close },
          }),
          _c("module-access-wallet-software", {
            attrs: {
              open: _vm.showSoftware,
              close: _vm.close,
              "wallet-type": _vm.type,
            },
          }),
          _c("enkrypt-missing-snackbar", {
            attrs: { show: _vm.showInstallEnkrypt },
            on: {
              closeEnkryptMissingSnackbar: function ($event) {
                _vm.showInstallEnkrypt = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }